jQuery(document).ready(function ($) {
	$(".slider__wrapper").slick({
		infinite: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		pauseOnHover: true,
		pauseOnFocus: true,
		pauseOnDotsHover: true,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	});
});

import SimpleLightbox from "simple-lightbox";
if (document.querySelector(".slider")) {
	setTimeout(() => {
		if (window.matchMedia("(min-width: 768px)").matches) {
			gsap.from('a[data-slick-index="2"]', {
				left: "-1000px",
				duration: 0.5,
				delay: 0.5,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});
			gsap.from('a[data-slick-index="1"]', {
				left: "-1000px",
				duration: 0.5,
				delay: 1,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});

			gsap.from('a[data-slick-index="3"]', {
				right: "-1000px",
				duration: 0.5,
				delay: 1,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});

			gsap.from(CSSRulePlugin.getRule(".slider:before"), {
				left: "-400px",
				duration: 0.5,
				delay: 1.3,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});

			gsap.from(CSSRulePlugin.getRule(".slider:after"), {
				right: "-400px",
				duration: 0.5,
				delay: 1.3,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});

			gsap.from(".slick-prev", {
				left: "-400px",
				duration: 0.5,
				delay: 1.5,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});

			gsap.from(".slick-next", {
				right: "-400px",
				duration: 0.5,
				delay: 1.5,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
				onComplete() {
					jQuery(document).ready(function ($) {
						const slidesCount =
							document.querySelectorAll(".slider img");

						let autoSwipe = true;

						document
							.querySelector(".slider")
							.addEventListener("mouseover", () => {
								autoSwipe = false;
							});
						document
							.querySelector(".slider")
							.addEventListener("mouseleave", () => {
								autoSwipe = true;
							});
						setInterval(() => {
							if (
								autoSwipe &&
								!document.querySelector(".slbElement")
							) {
								$(".slider__wrapper").slick("slickNext");
							}
						}, 2500);
					});
				},
			});

			gsap.from('a[data-slick-index="0"]', {
				left: "-500px",
				duration: 0.5,
				delay: 1.5,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});

			gsap.from('a[data-slick-index="4"]', {
				right: "-500px",
				duration: 0.5,
				delay: 1.5,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});
		} else if (
			window.matchMedia("(max-width: 768px)").matches &&
			window.matchMedia("(min-width: 576px)").matches
		) {
			gsap.from('a[data-slick-index="0"]', {
				left: "-500px",
				duration: 0.5,
				delay: 1.5,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});
			gsap.from('a[data-slick-index="2"]', {
				left: "-1000px",
				duration: 0.5,
				delay: 0.5,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});
			gsap.from('a[data-slick-index="1"]', {
				left: "-1000px",
				duration: 0.5,
				delay: 1,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});
			gsap.from(CSSRulePlugin.getRule(".slider:before"), {
				left: "-400px",
				duration: 0.5,
				delay: 1.3,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});

			gsap.from(CSSRulePlugin.getRule(".slider:after"), {
				right: "-400px",
				duration: 0.5,
				delay: 1.3,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});

			gsap.from(".slick-prev", {
				left: "-400px",
				duration: 0.5,
				delay: 1.5,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});

			gsap.from(".slick-next", {
				right: "-400px",
				duration: 0.5,
				delay: 1.5,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
				onComplete() {
					jQuery(document).ready(function ($) {
						function getRandomArbitrary(min, max) {
							return Math.random() * (max - min) + min;
						}

						const slidesCount =
							document.querySelectorAll(".slider img");

						let autoSwipe = true;

						document
							.querySelector(".slider")
							.addEventListener("mouseover", () => {
								autoSwipe = false;
							});
						document
							.querySelector(".slider")
							.addEventListener("mouseleave", () => {
								autoSwipe = true;
							});
						setInterval(() => {
							if (
								autoSwipe &&
								!document.querySelector(".slbElement")
							) {
								$(".slider__wrapper").slick("slickNext");
							}
						}, 2500);
					});
				},
			});
		} else if (window.matchMedia("(max-width: 576px)").matches) {
			gsap.from('a[data-slick-index="0"]', {
				left: "-500px",
				duration: 0.5,
				delay: 0.5,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});
			gsap.from(CSSRulePlugin.getRule(".slider:before"), {
				left: "-400px",
				duration: 0.5,
				delay: 0.3,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});

			gsap.from(CSSRulePlugin.getRule(".slider:after"), {
				right: "-400px",
				duration: 0.5,
				delay: 0.8,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});

			gsap.from(".slick-prev", {
				left: "-400px",
				duration: 0.5,
				delay: 0.5,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
			});

			gsap.from(".slick-next", {
				right: "-500px",
				duration: 0.5,
				delay: 0.5,
				scrollTrigger: {
					trigger: ".slider",
					start: "50% bottom",
				},
				onComplete() {
					jQuery(document).ready(function ($) {
						function getRandomArbitrary(min, max) {
							return Math.random() * (max - min) + min;
						}

						const slidesCount =
							document.querySelectorAll(".slider img");

						let autoSwipe = true;

						document
							.querySelector(".slider")
							.addEventListener("mouseover", () => {
								autoSwipe = false;
							});
						document
							.querySelector(".slider")
							.addEventListener("mouseleave", () => {
								autoSwipe = true;
							});
						setInterval(() => {
							if (
								autoSwipe &&
								!document.querySelector(".slbElement")
							) {
								$(".slider__wrapper").slick("slickNext");
							}
						}, 2500);
					});
				},
			});
		}
	}, 1000);

	document.addEventListener("DOMContentLoaded", () => {
		new SimpleLightbox({ elements: ".slider__wrapper a" });
	});
}
